import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import './App.css';
import 'leaflet/dist/leaflet.css';
import StyledPageContent from './StyledPageContent';
import EmailForm from './EmailForm';

const Map = lazy(() => import('./Map'));

function App() {
  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isPastData, setIsPastData] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date().toISOString().slice(0, 16));
  const [slidePositions, setSlidePositions] = useState({});

  const baseInputData = {
    "image_places": ["測点A", "測点B", "測点C"],
    "folder_values": [13.2, 15.2, 15.3],
    "latlon_places": [[42.926437, 143.378215], [42.940807, 143.341826], [42.931473, 143.323724]],
    "page_params": {
      "discripts": [
        ["こちらは測点Aに関する解析結果です。", "Aの本文です。", "a.jpg"],
        ["こちらは測点Bに関する解析結果です。", "Bの本文です。", "b.jpg"],
        ["こちらは測点Cに関する解析結果です。", "Cの本文です。", "c.jpg"]
      ]
    }
  };

  const decodeUnicode = (str) => {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, (match, grp) => {
      return String.fromCharCode(parseInt(grp, 16));
    });
  };

  const [inputData, setInputData] = useState(JSON.stringify({ nowtime: currentTime.toISOString(), ...baseInputData }));

  const generateImageValues = useCallback(() => {
    return baseInputData.image_places.map(() =>
      Array.from({ length: 100 }, () => +(Math.random() * 20).toFixed(2))
    );
  }, []);

  const [imageValues, setImageValues] = useState([]);

  useEffect(() => {
    setImageValues(generateImageValues());
  }, [generateImageValues]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!isPastData) {
      setInputData(JSON.stringify({ nowtime: currentTime.toISOString(), ...baseInputData }));
    }
  }, [currentTime, isPastData]);

  const handleDateTimeChange = (e) => {
    setSelectedDateTime(e.target.value);
    if (isPastData) {
      setInputData(JSON.stringify({ nowtime: new Date(e.target.value).toISOString(), ...baseInputData }));
    }
  };

  const handlePastDataCheckbox = (e) => {
    setIsPastData(e.target.checked);
    if (e.target.checked) {
      setInputData(JSON.stringify({ nowtime: new Date(selectedDateTime).toISOString(), ...baseInputData }));
    } else {
      setInputData(JSON.stringify({ nowtime: currentTime.toISOString(), ...baseInputData }));
    }
  };

  const getMaxSlide = useCallback(() => {
    if (result && result.image_places && result.image_places[activeTab]) {
      return result.image_places[activeTab].length - 1;
    }
    return 0;
  }, [result, activeTab]);

  const setSlideWithinBounds = useCallback((slide) => {
    const maxSlide = getMaxSlide();
    return Math.max(0, Math.min(slide, maxSlide));
  }, [getMaxSlide]);

  useEffect(() => {
    setCurrentSlide(prevSlide => setSlideWithinBounds(prevSlide));
  }, [activeTab, result, setSlideWithinBounds]);

const handleProcess = async () => {
  setProcessing(true);
  setStatus('処理を開始しました...');
  setResult(null);
  setActiveTab(0);
  setCurrentSlide(0);
  setSlidePositions({});

  try {
    const response = await fetch(`/api/process`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: inputData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Received data:', data); // デバッグ用

    if (data.error) {
      throw new Error(data.error);
    }

    setStatus(prevStatus => prevStatus + (data.status || '完了') + '\n');
    
    if (data.image_places) {
      setResult(data);
      setImageValues(generateImageValues());
    }
  } catch (error) {
    console.error('Error processing:', error);
    setStatus(prevStatus => prevStatus + `エラーが発生しました: ${error.message}\n`);
  } finally {
    setProcessing(false);
  }
};

  const handleTabChange = (index) => {
    setActiveTab(index);
    const folderName = baseInputData.image_places[index];
    const savedPosition = slidePositions[folderName] || 0;
    setCurrentSlide(savedPosition);
  };

  const handleSlideChange = (value) => {
    const newSlide = setSlideWithinBounds(value);
    setCurrentSlide(newSlide);
    const folderName = baseInputData.image_places[activeTab];
    setSlidePositions(prev => ({ ...prev, [folderName]: newSlide }));
  };

  const handleMapPinClick = (index) => {
    handleTabChange(index);
  };

const handlePDFDownload = async () => {
  try {
    setStatus('PDFを生成中...');
    const response = await fetch(`/api/generate-pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        image_places: result.image_places.map(urls => urls[0].split('/').slice(-2, -1)[0]),
        latlon_places: result.latlon_places,
        page_params: {
          ...result.page_params,
          discripts: result.page_params.discripts.map(discript => [
            discript[0],
            discript[1],
            discript[2]
          ])
        },
        folder_values: baseInputData.folder_values,
        image_values: imageValues
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'PDF generation failed');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'generated.pdf';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setStatus('PDFのダウンロードが完了しました。');
  } catch (error) {
    console.error('Error downloading PDF:', error);
    setStatus(`PDFのダウンロードに失敗しました: ${error.message}`);
  }
};

  const handleSendEmail = async (emailData) => {
    try {
      const pdfResponse = await fetch(`/api/generate-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          image_places: result.image_places.map(urls => urls[0].split('/').slice(-2, -1)[0]),
          latlon_places: result.latlon_places,
          page_params: {
            ...result.page_params,
            discripts: result.page_params.discripts.map(discript => [
              discript[0],
              discript[1],
              discript[2]
            ])
          },
          folder_values: baseInputData.folder_values,
          image_values: imageValues
        }),
      });

      if (!pdfResponse.ok) {
        throw new Error('PDF generation failed');
      }

      const pdfBlob = await pdfResponse.blob();

      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'generated.pdf');
      formData.append('to', emailData.to);
      formData.append('cc', emailData.cc);
      formData.append('bcc', emailData.bcc);
      formData.append('subject', emailData.subject);
      formData.append('text', emailData.text);

      const emailResponse = await fetch(`/api/send-email`, {
        method: 'POST',
        body: formData,
      });

      if (!emailResponse.ok) {
        throw new Error('Email sending failed');
      }

      alert('メールが送信されました。');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('メールの送信に失敗しました。');
    }
  };

  return (
    <div className="App">
      <h1>PIVによる流量計測の管理プラットフォーム</h1>
      <div className="processing-section">
        <textarea
          value={inputData}
          onChange={(e) => setInputData(e.target.value)}
          rows="4"
          cols="50"
        />
        <br />
        <button onClick={handleProcess} disabled={processing}>
          {processing ? '処理中...' : '処理開始'}
        </button>
        {result && (
          <button onClick={handlePDFDownload} style={{ marginLeft: '10px' }}>
            PDF ダウンロード
          </button>
        )}
      </div>
      <div className="datetime-section">
        <p>現在時刻: {currentTime.toLocaleString()}</p>
        <label>
          <input
            type="checkbox"
            checked={isPastData}
            onChange={handlePastDataCheckbox}
          />
          過去データから処理
        </label>
        {isPastData && (
          <input
            type="datetime-local"
            value={selectedDateTime}
            onChange={handleDateTimeChange}
          />
        )}
      </div>
      <pre>{status}</pre>
      {result && result.image_places && (
        <div className="result-container">
          <div className="result-map-container">
            <div className="image-section">
              <h2>
                処理結果:
                {baseInputData.folder_values.map((value, index) => (
                  <span key={index} className="folder-value">
                    {decodeUnicode(baseInputData.image_places[index])}: {value.toFixed(2)}
                  </span>
                ))}
              </h2>
              <div className="tab-buttons">
                {result.image_places.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handleTabChange(index)}
                    className={`tab-button ${activeTab === index ? 'active' : ''}`}
                  >
                    フォルダ {decodeUnicode(decodeURIComponent(result.image_places[index][0].split('/').slice(-2, -1)[0]))}
                  </button>
                ))}
              </div>
              <div className="image-display">
                <img
                  src={result.image_places[activeTab][currentSlide]}
                  alt={`Slide ${currentSlide + 1}`}
                />
                {imageValues[activeTab] && (
                  <div className="slide-value">
                    値: {imageValues[activeTab][currentSlide]}
                  </div>
                )}
              </div>
              <div className="slider-container">
                <input
                  type="range"
                  min="0"
                  max={getMaxSlide()}
                  value={currentSlide}
                  onChange={(e) => handleSlideChange(Number(e.target.value))}
                />
                <p>
                  スライド {currentSlide + 1} / {result.image_places[activeTab].length}
                </p>
              </div>
            </div>
            {result.latlon_places && result.latlon_places.length > 0 && (
              <div className="map-section">
                <h3>地図:</h3>
                <Suspense fallback={<div>地図を読み込んでいます...</div>}>
                  <Map
                    latlon_places={result.latlon_places}
                    handleTabChange={handleMapPinClick}
                    activeTab={activeTab}
                  />
                </Suspense>
              </div>
            )}
          </div>
          {result.page_params && (
            <StyledPageContent pageParams={result.page_params} activeTab={activeTab} />
          )}
          <div className="email-form-container">
            <h3>メール送信</h3>
            <EmailForm onSendEmail={handleSendEmail} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
