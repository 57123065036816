import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'leaflet/dist/leaflet.css';

const correctId = "admin_sip";
const correctPw = "bc7868";

const AuthWrapper = () => {
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = () => {
    if (id === correctId && pw === correctPw) {
      setIsAuthenticated(true);
    } else {
      setError('IDまたはパスワードが間違っています。');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="auth-wrapper">
        <div className="login-container">
          <h2>ログイン</h2>
          <div>
            <label>ID: </label>
            <input
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </div>
          <div>
            <label>パスワード: </label>
            <input
              type="password"
              value={pw}
              onChange={(e) => setPw(e.target.value)}
            />
          </div>
          <button onClick={handleLogin}>ログイン</button>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    );
  }

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthWrapper />
  </React.StrictMode>
);
