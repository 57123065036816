import React from 'react';

function StyledPageContent({ pageParams, activeTab }) {
  if (!pageParams || !pageParams.discripts || pageParams.discripts.length === 0) {
    return null;
  }

  const activeContent = pageParams.discripts[activeTab] || pageParams.discripts[0];

  return (
    <div className="styled-page-content">
      <h2>測点詳細</h2>
      <div dangerouslySetInnerHTML={{ __html: activeContent[0] }} />
      <p>{activeContent[1]}</p>
      {activeContent[2] && (
        <img src={activeContent[2]} alt="Content image" style={{ maxWidth: '100%', marginTop: '20px' }} />
      )}
    </div>
  );
}

export default StyledPageContent;