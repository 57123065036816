import React, { useState } from 'react';

function EmailForm({ onSendEmail }) {
  const [to, setTo] = useState('');
  const [cc, setCc] = useState('');
  const [bcc, setBcc] = useState('');
  const [subject, setSubject] = useState('レポート');
  const [text, setText] = useState('添付のレポートをご確認ください。');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSendEmail({ to, cc, bcc, subject, text });
  };

  return (
    <form onSubmit={handleSubmit} className="email-form">
      <div className="form-group">
        <label htmlFor="to">宛先：</label>
        <input
          type="email"
          id="to"
          value={to}
          onChange={(e) => setTo(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="cc">CC：</label>
        <input
          type="email"
          id="cc"
          value={cc}
          onChange={(e) => setCc(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="bcc">BCC：</label>
        <input
          type="email"
          id="bcc"
          value={bcc}
          onChange={(e) => setBcc(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="subject">件名：</label>
        <input
          type="text"
          id="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="text">本文：</label>
        <textarea
          id="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
          rows="4"
        />
      </div>
      <button type="submit">メール送信</button>
    </form>
  );
}

export default EmailForm;